/* styles/CookieConsent.module.css */
.cookieConsentContainer {
  background: #eae8ff;
  color: #ffffff;
  font-size: 14px;
  padding: 0 0 15px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cookieConsentMessage {
  color: var(--app-text-color);
  margin: 0 20px 0 0;

  >a {
    // border-bottom: ;
    text-decoration: underline;
  }
}

.cookieConsentButton {
  background: #eae8ff;
  color: #ffffff;
  font-size: 13px;
  border: none;
  border-radius: 5px;
  // padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px;
}

.cookieConsentButton:hover {
  background: #eae8ff;
}

.cookieConsentButtons {
  display: flex;
  align-items: center;
}

// buttonClasses={styles.btnStyle}
// declineButtonClasses={styles.declineBtnStyle}
// buttonStyle={{
//   backgroundColor: 'var(--app-primary-color)',
//   color: `var(--other-text-color)`,
//   fontSize: '14px',
//   borderRadius: '4px',
//   padding: '8px 16px',
//   marginRight: '8px',
// }}
// declineButtonStyle={{
//   backgroundColor: '#fff',
//   color: '#000',
//   fontSize: '14px',
//   borderRadius: '4px',
//   padding: '8px 16px',
//   marginRight: '8px',
// }}

@media screen and (max-width: 576px) {

  //   .cookieConsentContainer {
  //     padding-bottom: 80px;
  //   }
  .cookieConsentMessage {
    margin: 0;
  }

  // .cookieConsentButtons {
  //   flex-direction: column;
  //   align-items: flex-start;
  // }
}
